import { getLoggedInUser } from '../../helpers/authUtils';
import { fetchJSON } from '../../helpers/api';
import { Cookies } from 'react-cookie';
import './../../config.js';

const init_tickets = [
    {
        'id': 1,
        "format": "online",
        "lecturer_id": "0",
        "time_based_booking":false,
        'additional': [
            {
                'id': 1,
            }
        ],
    }
]

const data = [{
    "id": 1,
    'format': 'online',
    "lecturer_id": "0",
    "additional": [
        {
            "id": 1,
        }
    ],
    "courses": [{
        "id": 1,
        "description": "123",
        "startDate": "2020-12-18T01:00:00.000Z",
        "endDate": "2020-12-18T11:00:00.000Z",
        "text": "subject1",
    },
    {
        "id": 2,
        "description": "123",
        "startDate": "2020-12-19T01:00:00.000Z",
        "endDate": "2020-12-19T11:00:00.000Z",
        "text": "subject2",
    }]
}
];

const INIT_STATE = {
    activeID: 1,
    activeIndex: 0,
    courseIndex: 0,
    tickets: init_tickets,
    ticket_type: [{ 'value': "ottik", 'title': "One time Ticket" }],
};

const CourseManage = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "GET_TICKET_ACTIVE_ID":
            return { ...state, activeID: action.activeID, activeIndex: action.activeIndex }
        case "GET_TICKET_COURSE_ID":
            return { ...state, activeID: action.activeID, courseIndex: action.courseIndex }
        case "SCHEDULER_ADD_SUBJECT":
            return { ...state }
        case "ADD_TICKET_SUBJECT":
            return { ...state, schedulerData: action.schedulerData }
        case "UPDATE_TICKETS":
            return { ...state, tickets: action.tickets }
        case "UPDATE_EDITOR":
            return { ...state, editor: action.editor }
        case "UPDATE_TICKET_TYPE":
            console.log('UPDATE_TICKET_TYPE',action.ticket_type);
            return { ...state, ticket_type: action.ticket_type }
        case "UPDATE_INIT_ID":
            return { ...state, ticket_id: action.ticket_id, org_id: action.org_id, activeIndex: 0 }
        case "UPDATE_INIT_DATA":
            console.log('UPDATE_INIT_DATA',action.init_data);
            return { ...state, init_data: action.init_data }
        case "UPDATE_COURSE_ID":
            return { ...state, course_id: action.course_id, courseIndex: action.courseIndex }
        case "UPDATE_STATUS":
            console.log('UPDATE_STATUS',action.status);
            return { ...state, status: action.status}
        default:
            console.log('init');
            return { ...state };
    }
}

export default CourseManage;
