// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import { fetchJSON } from '../helpers/api';

const setSession = user => {
    let cookies = new Cookies();
    if (user){
        cookies.set('user', JSON.stringify(user), { path: '/' });
		// window.location.reload();
    }	

    else cookies.remove('user', { path: '/' });
};

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    return true;
    // const decoded = jwtDecode(user.token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //     console.warn('access token expired');
    //     return false;
    // } else {
    //     return true;
    // }
};

const isUserRoleChanged = (role) => {
    let user = setUserLastrole(role);
    if (!user) {
        return false;
    }
    return true;
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    const token = cookies.get('token');
    // console.log(user)
    // console.log(user.username);
    // console.log(user);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const setUserLastrole = (role) => {
    let cookies = new Cookies();
        let token = cookies.get('token');
    
        var method = "PATCH";
        var url = global.api.url + "user";
        var body = 'last_role=' + role;
        var headers = {
            'content-type': "application/x-www-form-urlencoded",
            'Authorization': token,
        }
        let options = {
            headers: headers,
            body: body,
            method: method,
        }
        var res = fetchJSON(url,options).then(data => {
            setSession(data);
            //window.location.reload(); 
            return data;
        })          
}

const postLike = (category,id) => {
    let cookies = new Cookies();
    let token = cookies.get('token');

    let formdata = new FormData();
    formdata.append('category', category);
    formdata.append('id', id);

    var method = "POST";
    var url = global.api.url + "likes";
    var body = formdata
    var headers = {
        'Authorization': token,
    }
    let options = {
        headers: headers,
        body: body,
        method: method,
    }
    var res = fetchJSON(url,options).then(data => {       
        return data;
    })  
}

const postBookmark = (program_id) => {
    let cookies = new Cookies();
    let token = cookies.get('token');

    let formdata = new FormData();
    formdata.append('program_id', program_id);
    // formdata.append('ticket_id', ticket_id);
    // formdata.append('course_id', course_id);

    var method = "POST";
    var url = global.api.url + "bookmarks";
    var body = formdata
    var headers = {
        'Authorization': token,
    }
    let options = {
        headers: headers,
        body: body,
        method: method,
    }
    var res = fetchJSON(url,options).then(data => {       
        return data;
    })  
}

const postFollows = (orgId) => {
    let cookies = new Cookies();
    let token = cookies.get('token');

    let formdata = new FormData();
    formdata.append('organization_id', orgId);

    var method = "POST";
    var url = global.api.url + "follows";
    var body = formdata
    var headers = {
        'Authorization': token,
    }
    let options = {
        headers: headers,
        body: body,
        method: method,
    }
    var res = fetchJSON(url,options).then(data => {       
        return data;
    })  
}

export { isUserAuthenticated, getLoggedInUser, isUserRoleChanged, setUserLastrole, postLike, postBookmark,postFollows };
