import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const changeRole = React.lazy(() => import('../pages/auth/changeRole'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const RegisterNext = React.lazy(() => import('../pages/auth/registerNext'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const InputPhone = React.lazy(() => import('../pages/auth/mobileLogin'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const EmailInbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
const ProjectList = React.lazy(() => import('../pages/apps/Project/List'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const TaskBoard = React.lazy(() => import('../pages/apps/Tasks/Board'));
// Education
//const FamilyList = React.lazy(() => import('../pages/Education'));


// pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Profile = React.lazy(() => import('../pages/other/Profile/'));
const Activity = React.lazy(() => import('../pages/other/Activity'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// ui
const BSComponents = React.lazy(() => import('../pages/uikit/BSComponents/'));
const FeatherIcons = React.lazy(() => import('../pages/uikit/Icons/Feather'));
const UniconsIcons = React.lazy(() => import('../pages/uikit/Icons/Unicons'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets/'));

// charts
const Charts = React.lazy(() => import('../pages/charts/'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editor = React.lazy(() => import('../pages/forms/Editor'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));
//Intro
const Intro = React.lazy(() => import('../pages/intro/introduction'));

// Education
const Education = React.lazy(() => import('../pages/Education'));
const campPrograms = React.lazy(() => import('../pages/Education/programType/campPrograms'));
const classEventPrograms = React.lazy(() => import('../pages/Education/programType/classEventPrograms'));
const freePrograms = React.lazy(() => import('../pages/Education/programType/freePrograms'));
const timebasedPrograms = React.lazy(() => import('../pages/Education/programType/timebasedPrograms'));

const CourseList = React.lazy(() => import('../pages/Education/course'));
//const OrgList = React.lazy(() => import('../pages/Education/organization'));
const OrganizationList = React.lazy(() => import('../pages/Education/organization/index'));
const TeacherList = React.lazy(() => import('../pages/Education/teachers/teacher'));

//family
const familyIndex = React.lazy(() => import('../pages/Family/index'));
const familySchedule = React.lazy(() => import('../pages/Family/schedule'));
//teacher
const teacherDetail = React.lazy(() => import('../pages/Education/teacherDetail/index'));
//profile
const userProfile = React.lazy(() => import('../pages/UserProfile/index'));
//program
const program = React.lazy(() => import('../pages/Program/index'));
//editProgram
const editProgram = React.lazy(() => import('../pages/editProgram/index'));
//Course
const course = React.lazy(() => import('../pages/Education/courseDetail/index'));
//Course online
const courseOnline = React.lazy(() => import('../pages/Course/index'));
//Course manage
const courseManage = React.lazy(() => import('../pages/Course/manage'));
//OrganizationLecturer
const lecturerDetail = React.lazy(() => import('../pages/myProgram/detail'));
const lecturerList = React.lazy(() => import('../pages/myProgram/list'));
const lecturerStudent = React.lazy(() => import('../pages/Students/index'));
const lecturerProgram = React.lazy(() => import('../pages/myProgram/program'));

const lecturerSchedule = React.lazy(() => import('../pages/Lecturer/index'));

const associateProgram = React.lazy(() => import('../pages/myProgram/associate'));
const familyBookings = React.lazy(() => import('../pages/myProgram/bookings'));
const familyBookmarks = React.lazy(() => import('../pages/myProgram/bookmarks'));
const familyWaiting = React.lazy(() => import('../pages/myProgram/waitinglist'));

//Educator
const educatorList = React.lazy(() => import('../pages/Educator/index'));

//Operations
const operations = React.lazy(() => import('../pages/Operation/index'));
const enrollments = React.lazy(() => import('../pages/Operation/Enrollment'));

//family payment
const familyPayment = React.lazy(() => import('../pages/Operation/family'));

//MessageList
const MessageList = React.lazy(() => import('../pages/MessageList/message'));
//NotificationList
const NotificationList = React.lazy(() => import('../pages/NotificationList/index'));

//Reset password
const ResetPassword = React.lazy(() => import('../pages/UserProfile/resetPassword'));

//Reset password
const Billing = React.lazy(() => import('../pages/UserProfile/billing'));

//Stripe Account-StripeSuccess
const StripeAccount = React.lazy(() => import('../pages/UserProfile/stripe'));

const StripeSuccess = React.lazy(() => import('../pages/UserProfile/Success'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            console.log(props);
            // return false;
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            console.log('current_loginUser', roles);
            if (roles && roles.indexOf(loggedInUser.last_role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }
            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    name: 'introduction',
    component: Education,
    //component: Intro,
    route: Route,
    //roles: ['Admin'],
    // path: '/',
    // exact: true,
    // component: () => <Redirect to="/dashboard" />,
    // route: Route
    //route: PrivateRoute,
};

const dashboardRoutes = {
    path: '/pages',
    name: 'Activities',
    header: 'Navigation',
    icon: FeatherIcon.Aperture,
    children: [
        {
            path: '/dashboard',
            name: 'All Activities',
            component: Education,
            route: Route,
        },
        {
            path: '/Education/freeProgram',
            name: 'Free Program',
            component: freePrograms,
            route: Route,
        },
        {
            path: '/Education/timebased',
            name: 'Time-Based Program',
            component: timebasedPrograms,
            route: Route,
        },
        {
            path: '/Education/classEvent',
            name: 'Class/Event',
            component: classEventPrograms,
            route: Route,
        },
        {
            path: '/Education/camp',
            name: 'Camp',
            component: campPrograms,
            route: Route,
        },
    ]
};

const TrendingRoutes = {
    path: '/pages',
    name: 'Educators',
    icon: FeatherIcon.Users,
    children: [
        {
            path: '/following',
            name: 'Self-employed teachers',
            component: TeacherList,
            route: Route,
        },
        {
            path: '/Organizations',
            name: 'Organizations',
            component: OrganizationList,
            route: Route,
        },
    ]
};

const FollowingRoutes = {
    path: '/following',
    name: 'Following',
    icon: FeatherIcon.Star,
    component: TeacherList,
    route: Route,
    //route: PrivateRoute
};

const BookmarkRoutes = {
    path: '/bookmark',
    name: 'Bookmark',
    icon: FeatherIcon.Bookmark,
    component: CourseList,
    route: Route,
    //route: PrivateRoute
};
const HistoryRoutes = {
    path: '/history',
    name: 'History',
    icon: FeatherIcon.Clock,
    component: familyIndex,
    route: Route,
    //route: PrivateRoute
};

const TeacherRoutes = {
    path: '/teacherDetail',
    name: 'Teacher',
    icon: FeatherIcon.Clock,
    component: teacherDetail,
    route: Route,
    //route: PrivateRoute
};

const ProfileRoutes = {
    path: '/userProfile',
    name: 'Profile',
    icon: FeatherIcon.Clock,
    component: userProfile,
    route: PrivateRoute,
    roles: ['educator', 'teacher', 'family'],
};

const CreateProgramRoutes = {
    path: '/program',
    name: 'Program',
    icon: FeatherIcon.Clock,
    component: program,
    route: PrivateRoute,
    roles: ['educator', 'teacher', 'family'],
};

const EditProgramRoutes = {
    path: '/editProgram',
    name: 'editProgram',
    icon: FeatherIcon.Clock,
    component: editProgram,
    route: PrivateRoute,
    roles: ['educator', 'teacher', 'family'],
};

const CourseDetailRoutes = {
    path: '/courseDetail',
    name: 'Course Detail',
    icon: FeatherIcon.Clock,
    component: course,
    route: Route,
    // route: PrivateRoute,
    // roles: ['educator'],
};

const CourseOnlineRoutes = {
    path: '/courseOnline',
    name: 'Course Online',
    icon: FeatherIcon.Clock,
    component: courseOnline,
    route: PrivateRoute,
    roles: ['family', 'educator'],
};

const CourseManageRoutes = {
    path: '/courseManage',
    name: 'Course Manage',
    icon: FeatherIcon.Clock,
    component: courseManage,
    route: Route,
    // route: PrivateRoute,
    //  roles: ['educator'],
};

const lecturerDetailRoutes = {
    path: '/lecturerDetail',
    name: 'Lecturer Detail',
    icon: FeatherIcon.Clock,
    component: lecturerDetail,
    route: Route,
    // route: PrivateRoute,
    // roles: ['educator'],
};

const lecturerListRoutes = {
    path: '/lecturer',
    name: 'Lecturer',
    header: 'Lecturer Dashboard',
    icon: FeatherIcon.PenTool,
    children: [
        {
            path: '/lecturer/lecturerList',
            name: 'Invitation List',
            component: lecturerList,
            route: PrivateRoute,
            roles: ['teacher'],
        },
        {
            path: '/myProgram/programlist',
            name: 'Programs',
            component: lecturerProgram,
            route: PrivateRoute,
            roles: ['teacher'],
        },
        {
            path: '/myProgram/programlist',
            name: 'Time-based Bookings',
            component: lecturerProgram,
            route: PrivateRoute,
            roles: ['teacher'],
        },
        {
            path: '/lecturer/Schedule',
            name: 'Schedule',
            component: lecturerSchedule,
            route: PrivateRoute,
            roles: ['teacher'],
        },
        {
            path: '/lecturer/studentlist',
            name: 'Student List',
            component: lecturerStudent,
            route: PrivateRoute,
            roles: ['teacher'],
        },
    ]
};

const MessageListRoutes = {
    path: '/messageList',
    name: 'Message List',
    icon: FeatherIcon.Clock,
    component: MessageList,
    route: Route,
    // route: PrivateRoute,
    // roles: ['educator'],
};

const pagesRoutes = {
    path: '/pages',
    name: 'Subjects',
    header: 'Categories',
    icon: FeatherIcon.Grid,
    children: [
        {
            path: '/pages/education/course',
            name: 'Course',
            component: CourseList,
            route: PrivateRoute,
            roles: ['admin'],
        },
        {
            path: '/pages/education/index',
            name: 'Education',
            component: Education,
            route: PrivateRoute,
            roles: ['admin'],
        },
        {
            path: '/pages/education/organization',
            name: 'Organization',
            component: OrganizationList,
            route: PrivateRoute,
            roles: ['admin'],
        },
        {
            path: '/pages/education/teachers',
            name: 'Teachers',
            component: TeacherList,
            route: PrivateRoute,
            roles: ['admin'],
        },
    ]
};

// family pages
const FamilyRoutes = {
    path: '/myfamily',
    name: 'My Family',
    header: 'Family Dashboard',
    icon: FeatherIcon.Eye,
    children: [
        {
            path: '/myfamily/profile',
            name: 'Family Profile',
            component: familyIndex,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/myProgram/bookings',
            name: 'My Activities',
            component: familyBookings,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/myProgram/bookmarks',
            name: 'Bookmarks',
            component: familyBookmarks,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/family/schedule',
            name: 'Scheduler',
            component: familySchedule,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/myProgram/waitinglist',
            name: 'Waiting List',
            component: familyWaiting,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/operation/family',
            name: 'Payments',
            component: familyPayment,
            route: PrivateRoute,
            roles: ['family'],
        },
    ]
};

const CourseListRoutes = {
    path: '/courseList',
    name: 'Course List',
    icon: FeatherIcon.Inbox,
    children: [
        {
            path: '/course/booking',
            name: 'Booking',
            component: Education,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/course/waiting',
            name: 'Waiting List',
            component: Education,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/course/ongoing',
            name: 'Ongoing',
            component: Education,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/course/likes',
            name: 'Likes',
            component: Education,
            route: PrivateRoute,
            roles: ['family'],
        },
    ]
};

//educator pages
const programRoutes = {
    path: '/myProgram',
    name: 'My Dashboard',
    header: 'Educator Dashboard',
    icon: FeatherIcon.Command,
    children: [
        // {
        //     path: '/myProgram/bookings',
        //     name: 'My Bookings',
        //     component: familyBookings,
        //     route: PrivateRoute,
        //     roles: ['educator'],
        // },        
        {
            path: '/myProgram/programlist',
            name: 'Programs',
            component: lecturerProgram,
            route: PrivateRoute,
            roles: ['educator'],
        },
        {
            path: '/myProgram/programlist',
            name: 'Time-Based Booking',
            component: lecturerProgram,
            route: PrivateRoute,
            roles: ['educator'],
        },
        {
            path: '/myProgram/associatelist',
            name: 'Collaborations',
            component: associateProgram,
            route: PrivateRoute,
            roles: ['educator'],
        },
        {
            path: '/organization/orgprofile',
            name: 'Organizations',
            component: educatorList,
            route: PrivateRoute,
            roles: ['educator'],
        },
        {
            path: '/financial/enrollments',
            name: 'Bookings & Enrollments',
            component: enrollments,
            route: PrivateRoute,
            roles: ['educator'],
        },
        {
            path: '/financial/mypayment',
            name: 'Payments',
            component: operations,
            route: PrivateRoute,
            roles: ['educator'],
        },
    ]
};

//family pages
const programFamilyRoutes = {
    path: '/myProgram',
    name: 'My Program',
    header: 'Family Dashboard',
    icon: FeatherIcon.Command,
    children: [
        {
            path: '/myProgram/bookings',
            name: 'My Bookings',
            component: familyBookings,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/myProgram/bookings',
            name: 'My Bookmark',
            component: familyBookings,
            route: PrivateRoute,
            roles: ['family'],
        },
        {
            path: '/myfamily/profile',
            name: 'Member Profile',
            component: familyIndex,
            route: PrivateRoute,
            roles: ['family'],
        },
    ]
};

const StudentsRoutes = {
    path: '/students',
    name: 'Student List',
    icon: FeatherIcon.Inbox,
    children: [
        {
            path: '/students/studentlist',
            name: 'Students',
            component: EmailInbox,
            route: PrivateRoute,
            roles: ['educator', 'teacher'],
        },
    ]
};

const orgRoutes = {
    path: '/organization',
    name: 'Organization',
    //header: 'Organization Management',
    icon: FeatherIcon.Umbrella,
    children: [
        {
            path: '/organization/orgprofile',
            name: 'Organization Profile',
            component: educatorList,
            route: PrivateRoute,
            roles: ['educator'],
        },
    ]
};
//familyPayment
const financialRoutes = {
    path: '/financial',
    name: 'My Account',
    header: 'Account Management',
    icon: FeatherIcon.Settings,
    children: [
        // {
        //     path: '/financial/mypayment',
        //     name: 'My Payment',
        //     component: operations,
        //     route: PrivateRoute,
        //     roles: ['family','teacher', 'educator'],
        // },
        {
            path: '/messageList',
            name: 'Message List',
            component: MessageList,
            route: PrivateRoute,
            roles: ['family', 'teacher', 'educator'],
        },
        {
            path: '/notificationList',
            name: 'Notification',
            component: NotificationList,
            route: PrivateRoute,
            roles: ['family', 'teacher', 'educator'],
        },
        {
            path: '/userProfile',
            name: 'Profile',
            component: userProfile,
            route: PrivateRoute,
            roles: ['family', 'teacher', 'educator'],
        },
    ]
};

const ResetPasswordRoutes = {
    path: '/resetPassword',
    name: 'Reset Password',
    icon: FeatherIcon.Clock,
    component: ResetPassword,
    route: PrivateRoute,
    roles: ['educator', 'family'],
};

const BillingRoutes = {
    path: '/Billing',
    name: 'billing Information',
    icon: FeatherIcon.Clock,
    component: Billing,
    route: PrivateRoute,
    roles: ['educator', 'family'],
};

const StripeAccountRoutes = {
    path: '/StripeAccount',
    name: 'Stripe Account',
    icon: FeatherIcon.Clock,
    component: StripeAccount,
    route: PrivateRoute,
    roles: ['educator', 'family'],
};


const StripeSuccessRoutes = {
    path: '/StripeSuccess',
    name: 'Stripe Success',
    icon: FeatherIcon.Clock,
    component: StripeSuccess,
    route: Route,
};

// apps - StripeAccount
const calendarAppRoutes = {
    path: '/apps/calendar',
    name: 'Calendar',
    header: 'Apps',
    icon: FeatherIcon.Calendar,
    component: CalendarApp,
    route: PrivateRoute,
    roles: ['admin'],
};

const emailAppRoutes = {
    path: '/apps/email',
    name: 'Email',
    icon: FeatherIcon.Inbox,
    children: [
        {
            path: '/apps/email/inbox',
            name: 'Inbox',
            component: EmailInbox,
            route: PrivateRoute,
            roles: ['admin'],
        },
        {
            path: '/apps/email/details',
            name: 'Details',
            component: EmailDetail,
            route: PrivateRoute,
            roles: ['admin'],
        },
        {
            path: '/apps/email/compose',
            name: 'Compose',
            component: EmailCompose,
            route: PrivateRoute,
            roles: ['admin'],
        },
    ]
};

const projectAppRoutes = {
    path: '/apps/projects',
    name: 'Projects',
    icon: FeatherIcon.Briefcase,
    children: [
        {
            path: '/apps/projects/list',
            name: 'List',
            component: ProjectList,
            route: PrivateRoute,
            roles: ['admin'],
        },
        {
            path: '/apps/projects/detail',
            name: 'Detail',
            component: ProjectDetail,
            route: PrivateRoute,
            roles: ['admin'],
        },
    ]
};

const taskAppRoutes = {
    path: '/apps/tasks',
    name: 'Tasks',
    icon: FeatherIcon.Bookmark,
    children: [
        {
            path: '/apps/tasks/list',
            name: 'List',
            component: TaskList,
            route: PrivateRoute,
            roles: ['admin'],
        },
        {
            path: '/apps/tasks/board',
            name: 'Board',
            component: TaskBoard,
            route: PrivateRoute,
            roles: ['admin'],
        },
    ],
};

const appRoutes = [calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes];

// components
const componentsRoutes = {
    path: '/ui',
    name: 'UI Elements',
    header: 'Components',
    icon: FeatherIcon.Package,
    children: [
        {
            path: '/ui/bscomponents',
            name: 'Bootstrap UI',
            component: BSComponents,
            route: PrivateRoute,
            roles: ['admin'],
        },
        {
            path: '/ui/icons',
            name: 'Icons',
            children: [
                {
                    path: '/ui/icons/feather',
                    name: 'Feather Icons',
                    component: FeatherIcons,
                    route: PrivateRoute,
                    roles: ['admin'],
                },
                {
                    path: '/ui/icons/unicons',
                    name: 'Unicons Icons',
                    component: UniconsIcons,
                    route: PrivateRoute,
                    roles: ['admin'],
                },
            ]
        },
        {
            path: '/ui/widgets',
            name: 'Widgets',
            component: Widgets,
            route: PrivateRoute,
            roles: ['admin'],
        },

    ]
};

// charts
const chartRoutes = {
    path: '/charts',
    name: 'Charts',
    component: Charts,
    icon: FeatherIcon.PieChart,
    roles: ['admin'],
    route: PrivateRoute
}


// forms
const formsRoutes = {
    path: '/forms',
    name: 'Forms',
    icon: FeatherIcon.FileText,
    children: [
        {
            path: '/forms/basic',
            name: 'Basic Elements',
            component: BasicForms,
            route: PrivateRoute,
        },
        {
            path: '/forms/advanced',
            name: 'Advanced',
            component: FormAdvanced,
            route: PrivateRoute,
        },
        {
            path: '/forms/validation',
            name: 'Validation',
            component: FormValidation,
            route: PrivateRoute,
        },
        {
            path: '/forms/wizard',
            name: 'Wizard',
            component: FormWizard,
            route: PrivateRoute,
            roles: ['educator'],
        },
        {
            path: '/forms/editor',
            name: 'Editor',
            component: Editor,
            route: PrivateRoute,
        },
        {
            path: '/forms/upload',
            name: 'File Upload',
            component: FileUpload,
            route: PrivateRoute,
        }
    ]
};


const tableRoutes = {
    path: '/tables',
    name: 'Tables',
    icon: FeatherIcon.Grid,
    children: [
        {
            path: '/tables/basic',
            name: 'Basic',
            component: BasicTables,
            route: PrivateRoute,
        },
        {
            path: '/tables/advanced',
            name: 'Advanced',
            component: AdvancedTables,
            route: PrivateRoute,
        }]
};

const familyRoutes = {
    path: '/tables',
    name: 'Tables',
    icon: FeatherIcon.Grid,
    children: [
        {
            path: '/tables/basic',
            name: 'Basic',
            component: BasicTables,
            route: PrivateRoute,
        },
        {
            path: '/tables/advanced',
            name: 'Advanced',
            component: AdvancedTables,
            route: PrivateRoute,
        }]
};

// Education
const educatorRoutes = {
    path: '/Education/list',
    name: 'List',
    component: Education,
    icon: FeatherIcon.PieChart,
    roles: ['admin'],
    route: PrivateRoute
}

const courseRoutes = {
    path: '/Education/course',
    name: 'course',
    component: CourseList,
    icon: FeatherIcon.PieChart,
    roles: ['admin'],
    route: PrivateRoute
}


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/changeRole',
            name: 'Change Role',
            component: changeRole,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/registerNext',
            name: 'registerNext',
            component: RegisterNext,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/InputPhone',
            name: 'Input Phone',
            component: InputPhone,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// based on the user role route different nested routes
const RolesRoutes = routes => {
    if (!isUserAuthenticated()) {
        return flattenRoutes(allVisitors);
    }
    const loggedInUser = getLoggedInUser();
    // check if route is restricted by role
    if (loggedInUser.role !== -1) {
        // role not authorised as right role
        return flattenRoutes(allFamilyRoutes);
    }
    return flattenRoutes(allRoutes);
};

// All routes
const allRoutes = [
    rootRoute,
    dashboardRoutes,
    TrendingRoutes,
    FollowingRoutes,
    BookmarkRoutes,
    HistoryRoutes,
    TeacherRoutes,
    FamilyRoutes,
    CourseListRoutes,
    ProfileRoutes,
    CreateProgramRoutes,
    EditProgramRoutes,
    programRoutes,
    programFamilyRoutes,
    CourseDetailRoutes,
    CourseOnlineRoutes,
    CourseManageRoutes,
    lecturerDetailRoutes,
    lecturerListRoutes,
    MessageListRoutes,
    ResetPasswordRoutes,
    BillingRoutes,
    StripeAccountRoutes,
    StripeSuccessRoutes,
    StudentsRoutes, orgRoutes, financialRoutes,
    ...appRoutes,
    pagesRoutes,
    componentsRoutes,
    chartRoutes,
    formsRoutes,
    tableRoutes,
    authRoutes
];

const allFamilyRoutes = [familyRoutes];
const allEducatorRoutes = [educatorRoutes, courseRoutes];

const authProtectedRoutes = [dashboardRoutes, ...appRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
//const allVisitorRoutes = RolesRoutes(allRoutes);
const allFamilys = [dashboardRoutes, TrendingRoutes, FamilyRoutes, financialRoutes]
const allVisitors = [dashboardRoutes, TrendingRoutes,];
const allEducators = [dashboardRoutes, TrendingRoutes, programRoutes, financialRoutes]
const allTeachers = [dashboardRoutes, TrendingRoutes, lecturerListRoutes, financialRoutes]
export { allRoutes, authProtectedRoutes, allFlattenRoutes, allFamilyRoutes, allEducatorRoutes, allVisitors, allFamilys, allEducators, allTeachers };
