import { Cookies } from 'react-cookie';
import { fetchJSON } from '../../helpers/api';

const getApiData = async (apiUrl) => {
    let cookies = new Cookies();
    let token = cookies.get('token');
    let url = global.api.url + apiUrl;
    let options = {
        method: "GET",
        headers:{
            'Authorization':token, 
        },
    }
    let res = await fetchJSON(url, options);
    return res;
}

const INIT_STATE = {
    onload:0
};

const CreateProgram = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "GET_INIT_DATA":
            console.log('create_p',action);
            return {...state,init_data:action.init_data}
        case "UPDATE_PROGRAM_DATA":
            console.log('create_p',action);
            console.log('create_p',action.program_data);
            return {...state,program_data:action.program_data}
        case "GET_ORG_ID":
            console.log('org_id',action.org_id);
            return {...state,org_id:action.org_id}
        case "ONLOAD":
            return {...state,onload:action.onload}
        case "INIT_DATA":
            return {...state,init_data:action.init_data}    
        default:
            return { ...state };
    }
}

export default CreateProgram;