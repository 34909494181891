import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Redirect, Link } from 'react-router-dom';
import { browserHistory } from 'react-router';
import { fetchJSON } from '../../helpers/api';
import { GET_TOPBAR_ACTIVE } from './actionType';
import { getTopbarActive } from './actions';




export function* watchGetTopbarActiveId() {
    // yield takeEvery(ADD_SCHEDULER, getTopbarActive);
}

function* myProgramSaga() {
    yield all([fork(watchGetTopbarActiveId)]);
}

export default myProgramSaga;