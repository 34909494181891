// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import courseManageSaga from './courseManage/courseManageSaga';
import myProgramSaga from './myProgram/myProgramSaga';
import createProgramSaga from './createProgram/createProgramSaga';

export default function* rootSaga(getState: any): any {
    yield all([authSaga(), layoutSaga(), appMenuSaga(), courseManageSaga(), myProgramSaga(), createProgramSaga()]);
}
