import { Cookies } from 'react-cookie';
import { fetchJSON } from '../../helpers/api';

const getApiData = async (apiUrl) => {
    let cookies = new Cookies();
    let token = cookies.get('token');
    let url = global.api.url + apiUrl;
    let options = {
        method: "GET",
        headers:{
            'Authorization':token, 
        },
    }
    let res = await fetchJSON(url, options);
    return res;
}

const INIT_STATE = {};

const MyProgram = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "GET_TOPBAR_ACTIVE":
            console.log('reducers',action);
            return {...state,activeID:action.activeID,activeIndex:action.activeIndex}
        default:
            return { ...state };
    }
}

export default MyProgram;