export const GET_TICKET_ACTIVE_ID = 'GET_TICKET_ACTIVE_ID';
export const GET_TICKET_COURSE_ID = 'GET_TICKET_COURSE_ID';
export const ADD_SCHEDULER = 'ADD_SCHEDULER';
export const SCHEDULER_UPDATE_SUBJECT = 'SCHEDULER_UPDATE_SUBJECT';
export const SCHEDULER_DELETE_SUBJECT = 'SCHEDULER_DELETE_SUBJECT';
export const ADD_SCHEDULER_SUBJECT = 'ADD_SCHEDULER_SUBJECT';
export const UPDATE_TICKETS = 'UPDATE_TICKETS';
export const UPDATE_EDITOR = "UPDATE_EDITOR";
export const UPDATE_TICKET_TYPE = "UPDATE_TICKET_TYPE";
export const UPDATE_INIT_ID = "UPDATE_INIT_ID";
export const UPDATE_COURSE_ID = "UPDATE_COURSE_ID";
export const UPDATE_INIT_DATA = "UPDATE_INIT_DATA";
export const UPDATE_STATUS = "UPDATE_STATUS";