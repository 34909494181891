// @flow
import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Redirect, Link } from 'react-router-dom';
import { browserHistory } from 'react-router';

import $ from 'jquery';
import '../../config';

import { fetchJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, CHANGE_ROLE, REGISTER_USER, FORGET_PASSWORD, SEND_CODE, UPDATA_PROFILE } from './constants';


import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
} from './actions';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = user => {
    let cookies = new Cookies();
    if (user) {
        cookies.set('user', JSON.stringify(user), { path: '/' });
        // window.location.reload();
    }
    else cookies.remove('user', { path: '/' });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, history } }) {
    // console.log(history);
    // console.log(history);
    // return false
    let cookies = new Cookies();
    let formdata = new FormData();
    formdata.append("email", username);
    formdata.append("password", password);
    const options = {
        body: formdata,
        method: 'POST',
    };

    try {
        // console.log(call(fetchJSON, global.api.url + 'authorizations', options));
        // console.log(yield call(fetchJSON, global.api.url + 'authorizations', options));
        const response = yield call(fetchJSON, global.api.url + 'authorizations', options);
        //console.log(response.access_token);
        cookies.set('token', 'Bearer ' + response.access_token);
        let token = cookies.get('token');
        // console.log(token);
        // cookies.setMaxAge(60*30);
        setSession(response.user);
        yield put(loginUserSuccess(response.user));
        // console.log(history.location.state.from.pathname);
        if (history.location.state) {
            history.push(history.location.state.from.pathname);
        } else {
            history.push('/dashboard');
        }
        window.location.reload();
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
        history.push('/account/login');
        window.location.reload();
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {

    try {
        setSession(null);
        yield call(() => {
            //history.push('/account/login');
            //logout to home dashboard instead of login page
            history.push('/');
            window.location.reload();
        });
    } catch (error) { }
}

/**
 * Switch user role
 * @param {*} param0
 */
function* changeRole({ payload: { role, history } }) {

    let cookies = new Cookies();
    let token = cookies.get('token');

    var method = "PATCH";
    var url = global.api.url + "user";
    var body = 'last_role=' + role;
    var headers = {
        'content-type': "application/x-www-form-urlencoded",
        'Authorization': token,
    }
    let options = {
        headers: headers,
        body: body,
        method: method,
    }

    try {
        const response = yield call(fetchJSON, url, options);

        console.log('user_from_changerole', response);
        setSession(response);
        if (history.location.state) {
            history.push('/');
        }
        window.location.reload();
    } catch (error) { }
}

/**
 * send verfication code
 */
function* sendcode({ payload: { sencode_email } }) {
    console.log('sendcode');
}

/**
 * update profile
 */
function* updateProfile({ payload: { file, phone, zipCode, history } }) {
    // console.log(file);
    let cookies = new Cookies();
    let token = cookies.get('token');
    // history.push('/dashboard');
    // console.log(token);
    let user = global.api.url + "user";

    let formdata = new FormData();
    formdata.append("avatar", file);
    formdata.append("phone", phone);
    formdata.append("zipcode", zipCode);

    $.ajax({
        type: "PATCH",
        headers: {
            'content-type': "application/x-www-form-urlencoded",
            'Authorization': token,
        },
        url: user,
        data: {
            avatar: file,
            phone: phone,
            zipcode: zipCode,
        },
        dataType: "json",
        success: function (e) {
            console.log(e);
            history.push('/');
        },
        error: function (err) {
            console.log(err);
        }
    });

    const options = {
        body: formdata,
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'content-type': "application/x-www-form-urlencoded",
            'Authorization': token,
        }
    };

}


/**
 * Register the user
 */
function* register({ payload: { username, email, password, zipcode, code, history } }) {

    let url = global.api.url + "users";
    //let url = "https://tk.xhfair.com/index.php/api/v1/users";
    let formdata = new FormData();
    formdata.append('username', username);
    formdata.append('email', email);
    formdata.append('password', password);
    formdata.append('zipcode', zipcode);
    formdata.append('code', code);
    let options = {
        body: formdata,
        method: "POST"
    }
    try {
        const response = yield call(fetchJSON, url, options);
        console.log(response);
        console.log(response.error);
        if (response.user) {
            let cookies = new Cookies();
            console.log('registed_token_user', response.user);
            cookies.set('token', 'Bearer ' + response.access_token);
            setSession(response.user);
            yield put(registerUserSuccess(response.user));
            if (history.location.state) {
                history.push('/');
            }
            window.location.reload();
        } else if (response.error) {
            console.log(response.error)
            yield put(registerUserFailed(response.error));
        }

    } catch (error) {
        console.log(error);
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            case 400:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}


/**
 * forget password
 */
function* forgetPassword({ payload: { email, password, code, history } }) {
    let url = global.api.url + "users/resetPassword";
    //let url = "https://tk.xhfair.com/index.php/api/v1/users/resetPassword";
    let formdata = new FormData();
    formdata.append('email', email);
    formdata.append('password', password);
    formdata.append('code', code);
    let options = {
        body: formdata,
        method: "POST"
    }
    try {
        const response = yield call(fetchJSON, url, options);
        if (response.user) {
            let cookies = new Cookies();
            cookies.set('token', 'Bearer ' + response.access_token);
            setSession(response.user);
            yield put(registerUserSuccess(response));
            history.push('/');
            window.location.reload();
        } else if (response.error) {
            console.log(response.error)
            yield put(registerUserFailed(response.error));
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }

}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchChangeRole() {
    yield takeEvery(CHANGE_ROLE, changeRole);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchSendCode() {
    yield takeEvery(SEND_CODE, sendcode);
}

export function* watchUpdateProfile() {
    yield takeEvery(UPDATA_PROFILE, updateProfile);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchChangeRole), fork(watchRegisterUser), fork(watchSendCode), fork(watchForgetPassword), fork(watchUpdateProfile)]);
}

export default authSaga;
