// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import CourseManage from './courseManage/courseManageReducers';
import MyProgram from './myProgram/myProgramReducers';
import CreateProgram from './createProgram/createProgramReducers';


export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    CourseManage,
    MyProgram,
    CreateProgram,

});
