// @flow
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { INIT_MENU, CHANGE_ACTIVE_MENU_FROM_LOCATION, CHANGE_ACTIVE_MENU_TO_FAMILY } from './constants';
import * as menuConstants from '../../constants/menu';
import { authProtectedRoutes as routes, allFamilyRoutes, allVisitors, allFamilys, allEducators, allTeachers } from '../../routes';
import assignIds from './utils';
import { initMenuSuccess, changeActiveMenuFromLocationSuccess } from './actions';
import { isUserAuthenticated, getLoggedInUser } from '../../helpers/authUtils';

/**
 * Activate menu items from location
 * @param {*} menuItems
 */
const getActivatedMenuItemIds = menuItems => {
    var matchingItems = [];
    for (var menuItem of menuItems) {
        if (window.location.pathname.indexOf(menuItem.path) === 0) {
            matchingItems.push(menuItem.id);
        }
        //matchingItems.push(menuItem.id);

        if (typeof menuItem.children !== 'undefined') {
            matchingItems = [...matchingItems, ...getActivatedMenuItemIds(menuItem.children)];
        }
    }
    return matchingItems;
};

/**
 * Initilizes the menu
 */
function* initMenuAndItems() {
    try {
        var menuItems = [];
        if (!isUserAuthenticated()) {
            menuItems = assignIds(allVisitors);
        }
        else {
            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (loggedInUser.last_role === null) {
                // role not authorised so redirect to home page
                menuItems = assignIds(allVisitors);
            }
            else if (loggedInUser.last_role && loggedInUser.last_role === 'family') {
                menuItems = assignIds(allFamilys);
            }
            else if (loggedInUser.last_role && loggedInUser.last_role === 'educator') {
                menuItems = assignIds(allEducators);
            }
            else if (loggedInUser.last_role && loggedInUser.last_role === 'admin') {
                menuItems = assignIds(routes);
            }
            else if (loggedInUser.last_role && loggedInUser.last_role === 'teacher') {
                menuItems = assignIds(allTeachers);
            }
            else {
                menuItems = assignIds(routes);
            }

        }


        yield put(initMenuSuccess(menuItems));
    } catch (error) { }
}

/**
 * change the active menu item based on location
 */
function* changeActiveMenuFromLocation() {
    try {
        //const menuItems = assignIds(routes);
        const menuItems = assignIds(allVisitors);
        const activatedMenuItemIds = yield call(getActivatedMenuItemIds, menuItems);
        yield put(changeActiveMenuFromLocationSuccess(activatedMenuItemIds));
    } catch (error) { }
}

/**
 * Changes the left sidebar menu by user role
 * @param {*} param0
 */
function* changeLeftMenuToFamily({ payload: family }) {
    var menuFamily = family.familyMenu;
    console.log(menuFamily);
    //family =menuConstants.ACTIVE_MENU_TO_FAMILY;

    try {
        switch (menuFamily) {
            case menuConstants.ACTIVE_MENU_TO_FAMILY:
                const menuItems = assignIds(routes);
                const activatedMenuItemIds = yield call(getActivatedMenuItemIds, menuItems);
                yield put(changeActiveMenuFromLocationSuccess(activatedMenuItemIds));
                break;
            // case menuConstants.ACTIVE_MENU_TO_EDUCATOR:
            //     const menuEItems = assignIds(allFamilyRoutes);
            //     const activatedEMenuItemIds = yield call(getActivatedMenuItemIds, menuEItems);
            //     yield put(changeActiveMenuFromLocationSuccess(activatedEMenuItemIds));
            //     break;
            default:
                const menuFItems = assignIds(allFamilyRoutes);
                const activatedFMenuItemIds = yield call(getActivatedMenuItemIds, menuFItems);
                yield put(changeActiveMenuFromLocationSuccess(activatedFMenuItemIds));
                break;
        }

    } catch (error) { }
}

/**
 * Watchers
 */
export function* watchMenuInit() {
    yield takeEvery(INIT_MENU, initMenuAndItems);
}

export function* watchMenuChange() {
    yield takeEvery(CHANGE_ACTIVE_MENU_FROM_LOCATION, changeActiveMenuFromLocation);
}

export function* watchUserMenuChange() {
    yield takeEvery(CHANGE_ACTIVE_MENU_TO_FAMILY, changeLeftMenuToFamily);
}

function* appMenuSaga() {
    yield all([fork(watchMenuInit), fork(watchMenuChange), fork(watchUserMenuChange)]);
}

export default appMenuSaga;
