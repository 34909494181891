import {
    GET_TICKET_ACTIVE_ID,
    GET_TICKET_COURSE_ID,
    ADD_SCHEDULER,
    UPDATE_TICKETS,
    UPDATE_EDITOR,
    UPDATE_TICKET_TYPE,
    UPDATE_INIT_ID,
    UPDATE_COURSE_ID,
    UPDATE_INIT_DATA,
    UPDATE_STATUS,
} from './actionType';


export const getTicketActiveId = ( active_id, activeIndex ) => ({
    type: GET_TICKET_ACTIVE_ID,
    activeID: active_id ,
    activeIndex: activeIndex,
    test:'test',
});

export const getTicketCourseId = ( active_id, courseIndex ) => ({
    type: GET_TICKET_COURSE_ID,
    activeID: active_id ,
    courseIndex: courseIndex,
    test:'test',
});

export const addScheduler = ( schedulerData ) => ({
    type: ADD_SCHEDULER,
    schedulerData: schedulerData ,
});

export const updateTickets = ( tickets ) => ({
    type: UPDATE_TICKETS,
    tickets: tickets ,
});

export const updateEditor = ( editor ) => ({
    type: UPDATE_EDITOR,
    editor: editor ,
});

export const updateTicketType = (ticket_type) => ({
    type: UPDATE_TICKET_TYPE,
    ticket_type: ticket_type ,
})

export const updateInit_id = (ticket_id, org_id) => ({
    type:UPDATE_INIT_ID,
    ticket_id:ticket_id,
    org_id:org_id,
})

export const updateCourseId = (course_id, courseIndex) => ({
    type:UPDATE_COURSE_ID,
    course_id:course_id,
    courseIndex:courseIndex,
})

export const update_init_data = (init_data) => ({
    type:UPDATE_INIT_DATA,
    init_data:init_data,
})

export const update_status = (status) => ({
    type:UPDATE_STATUS,
    status:status,
})




