import { Cookies } from 'react-cookie';
/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
			// console.log(response);
            let tokenInfo =response.headers.get('Authorization');
            if(tokenInfo){
                let cookies = new Cookies();
                cookies.set('token', tokenInfo);
                options = Object.assign(options,{Authorization:tokenInfo});
                // console.log(options);
                fetch(url, options)
                    .then(response => {
                        if (!response.status === 200) {
                            throw response.json();
                        }
                        return response.json();
                    })
            }
            if (!response.status === 200) {
                throw response.json();
            }
			console.log('fetch_response',response);
            return response.json();
        })
        .then(json => {
			console.log('fetch_json',json);
            return json;
        })
        .catch(error => {
            //token invalid;
            console.log('fetch_error',error);
            throw error;
        });
};

// const fetchWithDelay = (url, options = {}) => {
//     const promise = new Promise((resolve, reject) => {
//       setTimeout(() => {
//         resolve(fetch(url, {
//           method: 'GET',
//         })
//           .then((response) => response.json()));
//       }, 3000)
//     });
  
//     return promise;
//   }

export { fetchJSON };
