import {
    GET_TOPBAR_ACTIVE,
    ADD_SCHEDULER,
    UPDATE_TICKETS,
    UPDATE_EDITOR,
    UPDATE_TICKET_TYPE,
} from './actionType';

export const getTopbarActive = ( status ) => ({
    type: GET_TOPBAR_ACTIVE,
    status: status ,
});