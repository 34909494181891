// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    CHANGE_ROLE,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    SEND_CODE,
    UPDATA_PROFILE,
} from './constants';


export const loginUser = (username, password, history) => ({
    type: LOGIN_USER,
    payload: { username, password, history },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (username, email, password, zipcode, code, history) => ({
    type: REGISTER_USER,
    payload: { username, email, password,zipcode, code, history },
});

export const sendCode = ( sencode_email ) => ({
    type: SEND_CODE,
    payload: { sencode_email },
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const updateProfile = ( file, phone, zipCode, history ) => ({
    type: UPDATA_PROFILE,
    payload: { file, phone, zipCode, history },
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const changeRole = (role, history) => ({
    type: CHANGE_ROLE,
    payload: { role, history },
});

export const forgetPassword = (email, password,code,history) => ({
    type: FORGET_PASSWORD,
    payload: { email, password,code, history },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});
