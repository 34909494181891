import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Redirect, Link } from 'react-router-dom';
import { browserHistory } from 'react-router';
import { fetchJSON } from '../../helpers/api';
import { GET_TICKET_ACTIVE_ID, GET_TICKET_COURSE_ID, ADD_SCHEDULER, UPDATE_INIT_ID, UPDATE_TICKETS } from './actionType';
import { getTicketActiveId,addTicketSubject, updateTickets } from './actions';

function* getTicketActiveIdSaga ( {activeID ,test} ){
    // console.log('saga',activeID)
    // console.log(test);
    // console.log('saga',{activeID: { active_id }});
    // yield put(getTicketActiveId(active_id));
}

function* getTicketCourseIdSaga ( {activeID ,test} ){
    // console.log('saga',activeID)
    // console.log(test);
    // console.log('saga',{activeID: { active_id }});
    // yield put(getTicketActiveId(active_id));
}

function* getInitTickets( {ticket_id:ticket_id, org_id:org_id,} ){
    // const init_tickets = [ 
    //     { 
    //         'id':1,
    //         'format':'online',
    //         "lecturer_id": "0",
    //         'additional':[
    //             {   
    //                 'id': 1, 
    //             }
    //         ],
    //     }
    // ]
    // let tickets = '';
    // // console.log('ticket_id',ticket_id);
    // if(ticket_id){
    //     let cookies = new Cookies();
    //     let token = cookies.get('token');
    //     let url = global.api.url + 'ticket/' + ticket_id;
    //     let options = {
    //         method: "GET",
    //         headers:{
    //             'Authorization':token, 
    //         },
    //     }
    //     var res =yield fetchJSON(url, options);
    //     // console.log('this_api',res);
    //     if(res.tickets.length>0){
    //         tickets = res.tickets;
    //     }else{
    //         // console.log('init_tickets');
    //         tickets = init_tickets;
    //     }
    // }
    // console.log('saga_tickets',tickets);
    // yield put(updateTickets(tickets));
    // yield put(getTicketActiveId(0,0))

		
}


export function* watchGetTicketActiveId() {
    yield takeEvery(GET_TICKET_ACTIVE_ID, getTicketActiveIdSaga);
}

export function* watchGetTicketCourseId() {
    yield takeEvery(GET_TICKET_COURSE_ID, getTicketCourseIdSaga);
}


export function* watchGetInitTickets() {
	console.log('saga')
    yield takeEvery(UPDATE_INIT_ID, getInitTickets);
}

function* courseManageSaga() {
    yield all([fork(watchGetTicketActiveId), fork(watchGetInitTickets), fork(watchGetTicketCourseId)]);
}

export default courseManageSaga;