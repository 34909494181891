import {
    UPDATE_PROGRAM_DATA,
    GET_ORG_ID,
    ONLOAD,
    INIT_DATA
} from './actionType';

export const updateProgramData = ( program_data ) => ({
    type: UPDATE_PROGRAM_DATA,
    program_data: program_data ,
});

export const getOrgID = ( org_id ) => ({
    type: GET_ORG_ID,
    org_id: org_id ,
});

export const onload = ( onload ) => ({
    type: ONLOAD,
    onload: onload,
});

export const updata_init_data = ( init_data ) => ({
    type: INIT_DATA,
    init_data: init_data,
});